import * as React from 'react'
import querystring from 'querystring'

import styled from 'styled-components'
import Page from '../components/Page'

import Helmet from 'react-helmet'
import * as _ from 'lodash'
import { getLanguageCode } from '../styles/utils'
import { BlogArticleTeaserProps } from '../components/common/BlogArticleTeaser'
import BlogList from '../components/common/BlogList'
import LegalSection from '../components/common/LegalSection'

import { media, Col, Heading, Row, CodeControlIcon } from 'cc-ui-components'
import { graphql } from 'gatsby'
import IndexLayout from '../components/layout'
import { injectIntl, InjectedIntlProps, FormattedMessage } from 'react-intl'
import { RouteComponentProps } from '@reach/router'
import {
  CCLogo,
  ContentWrapper,
  CustomColLeft,
  CustomColRight,
  CustomRow,
  HomepageLink,
  Logos,
  LogosWrapper,
  Wrapper,
} from './get-in-touch'
import IconsSection from '../components/common/IconsSection'
// import * as SegmentAnalytics from '../lib/segment'

interface ThankYouProps extends RouteComponentProps {
  data: any
  intl?: any
}

const BlogListContainer = styled.div`
  & > div:first-of-type {
    & > div:first-of-type {
      justify-content: center;
    }
  }
  h2 {
    font-size: 15px;
    line-height: 22px;
  }
  ${media.sm`
    margin-top: 20px;
    h2 {
      font-size: 24px;
      font-height: 30px;
    }
  `};
`
function hsIdentify(email: string): void {
  // @ts-ignore
  window._hsq = window._hsq || []
  // @ts-ignore
  window._hsq.push(['identify', { email }])
}
class ThankYou extends React.Component<ThankYouProps & InjectedIntlProps> {

  render() {
    const props = this.props
    const { intl, location } = props
    let allPosts = _.get(props, 'data.allContentfulBlogPost.edges')
    allPosts = _.filter(
      allPosts,
      item =>
        item.node.node_locale === (intl.locale === 'en' ? 'en-US' : intl.locale)
    )
    allPosts = _.takeRight(allPosts, allPosts.length - 3)
    const articleTeasers = allPosts
      .map(
        ({ node }: any) =>
          ({
            image: node.headerImage,
            category: _.get(node, 'topic.0.title'),
            categoryID: _.get(node, 'topic.0.id'),
            title: node.title,
            pubDate: node.updatedAt,
            slug: `/${intl.locale}/blog/${node.slug}`,
          } as BlogArticleTeaserProps)
      )
      .slice(0, 2)
    const lang = getLanguageCode()
    if (location) {
      const { invitee_email } = querystring.parse(
        location.search.substring(1)
      )
      if (invitee_email) {
        hsIdentify(invitee_email as string)
      }
    }
    return (
      <Page>
        <FormattedMessage id={'contact.title'}>
          {title => (
            <Helmet htmlAttributes={{ lang }} title={title}>
              <meta
                name="description"
                content="Let us help you! We are waiting for for you. So get in contact…"
              />
            </Helmet>
          )}
        </FormattedMessage>
        <Wrapper>
          <CustomRow>
            <CustomColLeft>
              <ContentWrapper>
                <HomepageLink to={`${intl.locale}/home`}>
                  <CCLogo>
                    <CodeControlIcon />
                  </CCLogo>
                </HomepageLink>
                <Heading level="2" size="xxl" trailer={3} center>
                  <FormattedMessage id="thank_you.headline" />
                </Heading>
                <Heading level="3" size="md" secondary center trailer={1}>
                  <FormattedMessage id="thank_you.intro_line1" />
                </Heading>
                <Heading level="3" size="md" secondary center>
                  <FormattedMessage id="thank_you.intro_line2" />
                </Heading>
                <Heading
                  level="3"
                  size="md"
                  secondary
                  center
                  leader={3}
                  trailer={3}
                >
                  <FormattedMessage id="thank_you.intro_line3" />
                </Heading>
                <Row>
                  <Col sm={12} md={12} lg={11} center>
                    <BlogListContainer>
                      <BlogList articles={articleTeasers} itemLg={6} />
                    </BlogListContainer>
                  </Col>
                </Row>
                <Heading
                  level="3"
                  size="md"
                  secondary
                  uppercase
                  center
                  leader={5}
                >
                  <FormattedMessage id="thank_you.social_section.headline" />
                </Heading>
                <IconsSection />
                <LegalSection hideTerms />
              </ContentWrapper>
            </CustomColLeft>
            <CustomColRight>
              <LogosWrapper>{Logos()}</LogosWrapper>
            </CustomColRight>
          </CustomRow>
        </Wrapper>
      </Page>
    )
  }
}

const ThankYouWrapper = (props: ThankYouProps) => (
  <IndexLayout location={props.location}>
    {React.createElement(injectIntl(ThankYou), props)}
  </IndexLayout>
)

export default ThankYouWrapper

export const query = graphql`
  query ThankYouArticlesQueryEn {
    allContentfulBlogPost(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          node_locale
          title
          slug
          updatedAt(formatString: "DD MMMM YYYY")
          topic {
            id
            title
          }
          headerImage {
            fluid(maxWidth: 800, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulTopic {
      edges {
        node {
          id
          title
          slug
          node_locale
        }
      }
    }
  }
`
